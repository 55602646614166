import React, { useState, useEffect } from 'react';
import { usePWAInstall } from '../../contexts/PWAInstallContext';
import {IoShareOutline} from "react-icons/io5";

const IOSInstallInstructions = () => {
    const { getIOSInstallInstructions } = usePWAInstall();
    const instructions = getIOSInstallInstructions();

    return (
        <div className="ios-install-instructions">
            <h4>{instructions.title}</h4>
            <ul className={`list-none px-2`}>
                <li className={`inline-flex flex-row items-center gap-1`}>1. Tap the <IoShareOutline size={24}/> button at the bottom of the screen </li>
                {instructions.steps.map((step, index) => (
                    <li key={index}>{index+2}. {step}</li>
                ))}
            </ul>
        </div>
    );
};

const InstallBanner = ({
                           title = 'Add our app to your home screen!',
                           description = 'Install this app on your device for quick and easy access.',
                           buttonText = 'Install App',
                           delay = 1000, // Changed to 30 seconds default
                           showIOSInstructions = true,
                           dismissalTime = 7 * 24 * 60 * 60 * 1000
                       }) => {
    const {
        isInstalled,
        isInstallAvailable,
        promptToInstall,
        isIOS,
        isSafari,
        isStandalone
    } = usePWAInstall();

    const [showBanner, setShowBanner] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);
    const [userDismissed, setUserDismissed] = useState(false);

    // Check for stored dismissal time in localStorage when component mounts
    useEffect(() => {
        const dismissedUntil = localStorage.getItem('pwa-install-dismissed-until');
        if (dismissedUntil && Number(dismissedUntil) > Date.now()) {
            setUserDismissed(true);
        }
    }, []);

    // Show banner after delay
    useEffect(() => {
        // Don't show if:
        // - App is already installed
        // - User has dismissed the banner
        // - Running in standalone mode
        if (isInstalled || userDismissed || isStandalone) {
            return;
        }

        // Check if we should show the banner for iOS
        const shouldShowForIOS = isIOS && isSafari && !isStandalone;

        // Check if we should show for non-iOS (has install prompt)
        const shouldShowForNonIOS = !isIOS && isInstallAvailable;

        if (!shouldShowForIOS && !shouldShowForNonIOS) {
            return;
        }

        // Simple delay timer - no user engagement required
        const timer = setTimeout(() => {
            setShowBanner(true);
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [isInstallAvailable, isInstalled, isIOS, isSafari, isStandalone, delay, userDismissed]);

    const handleInstall = async () => {
        if (isIOS) {
            setShowInstructions(true);
        } else {
            await promptToInstall();
            setShowBanner(false);
        }
    };

    const handleDismiss = () => {
        // Set state to hide the banner
        setShowBanner(false);
        setShowInstructions(false);
        setUserDismissed(true);

        // Store dismissal time in localStorage
        const dismissalExpiry = Date.now() + dismissalTime;
        localStorage.setItem('pwa-install-dismissed-until', dismissalExpiry.toString());
    };

    if (!showBanner || isInstalled || isStandalone) {
        return null;
    }

    return (
        <div className={`pwa-install-banner w-[100%] h-[150px] bg-brand-purple fixed bottom-0 left-0 z-[9999999999999]`}>
            <div className="pwa-install-banner-content px-3">
                {!showInstructions ? (
                    <>
                        <h3>{title}</h3>
                        <p>{description}</p>
                        <div className={`pwa-install-banner-buttons flex gap-1`}>
                            <button className={`install-button rounded-tiny border-0 bg-brand-green p-2`} onClick={handleInstall}>
                                {isIOS ? "Show Instructions" : buttonText}
                            </button>
                            <button className={`dismiss-button rounded-tiny border-0 bg-dark-bg text-white p-2`} onClick={handleDismiss}>
                                Not now
                            </button>
                        </div>
                    </>
                ) : (
                     <>
                         {isIOS && showIOSInstructions && <IOSInstallInstructions />}
                         <button className={`dismiss-button absolute top-3 right-3`} onClick={handleDismiss}>
                             Close
                         </button>
                     </>
                 )}
            </div>
        </div>
    );
};

export default InstallBanner;
