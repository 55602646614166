import FlowerMapMarker from "../../assets/map-markers/flower-marker.svg";
import AccessoryMapMarker from "../../assets/map-markers/accessory-marker.svg";
import ConcentrateMapMarker from "../../assets/map-markers/concentrate-marker.svg";
import EdibleMapMarker from "../../assets/map-markers/edible-marker.svg";
import PrerollMapMarker from "../../assets/map-markers/preroll-marker.svg";
import TopicalMapMarker from "../../assets/map-markers/topical-marker.svg";
import VapeMapMarker from "../../assets/map-markers/vape-marker.svg";
import {TrendingUp} from "lucide-react";

import {ReactComponent as PrerollIcon} from "../../assets/preroll.svg";
import {ReactComponent as FlowerIcon} from "../../assets/flower.svg";
import {ReactComponent as CartridgeIcon} from "../../assets/cartridge.svg";
import {ReactComponent as TopicalIcon} from "../../assets/topical.svg";
import {ReactComponent as EdibleIcon} from "../../assets/edible.svg";
import {ReactComponent as AccessoryIcon} from "../../assets/accessory.svg";
import {ReactComponent as WaxIcon} from "../../assets/wax.svg";
import {ReactComponent as TrendingIcon} from "../../assets/trending.svg";


const BusinessFilters = [
    { name: 'All Products', Icon: TrendingUp, selectedLabel: 'All Products', lottie: 'marijuana',  param: 'all', id: 'all', type: 'icon' },
    { name: 'Flower', Icon: FlowerIcon, param: 'flower', id: 2, mapMarker: FlowerMapMarker },
    { name: 'Pre-Rolls', Icon: PrerollIcon, param: 'PreRoll', id: 402, mapMarker: PrerollMapMarker },
    { name: 'Vape Cartridges', Icon: CartridgeIcon, param: 'cartridge', id: 193, mapMarker: VapeMapMarker },
    { name: 'Concentrates', Icon: WaxIcon, param: 'concentrate', id: 361, mapMarker: ConcentrateMapMarker },
    { name: 'Edibles', Icon: EdibleIcon, param: 'edible', id: 371, mapMarker: EdibleMapMarker },
    { name: 'Topicals', Icon: TopicalIcon, param: 'topical', id: 424, mapMarker: TopicalMapMarker },
    { name: 'Accessories', Icon: AccessoryIcon, param: 'accessory', id: 350, mapMarker: AccessoryMapMarker },
];


BusinessFilters.getById = function(id) {
    return this.find(filter => filter.id === id);
};

BusinessFilters.getFilterByParam = function(param) {
    return this.find(filter => filter.param === param);
};

export default BusinessFilters;
