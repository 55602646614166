import React, {useEffect, useState, useCallback, useRef} from "react";
import ReactDOM from "react-dom";
import styles from "./DealListV2.module.css";
import {X, MapPin, Phone, Clock} from "lucide-react";
import useApi from "../../utils/useApi";
import useSafariNavBarState from "../../hooks/useSafariNavBarState";
import ProductView from "../ProductView/ProductView";
import {Link, useLocation, useNavigate} from "react-router-dom";
import eventBus from "../../utils/eventBus";
import LottieIcon from "../LottieIcon/LottieIcon";
import PriceComparison from "../PriceComparison/PriceComparison";
import {useUserActions} from "../../contexts/UserActionsContext";
import {useTheme} from "../ThemeContext/ThemeContext";

const DealBottomSheetV2 = ({
                               productId,
                               businessId,
                               isOpen,
                               onClose,
                               productOrig,
                               hideStore,
                               selectedWeight,
                               selectedWeightUnit,
                               priceFrom,
                               priceTo,
                               distance,
                           }) => {

    const {isDarkMode} = useTheme();
    const [customStyle, setCustomStyle] = useState({});
    const isNavExpanded = useSafariNavBarState();
    const [isIOSSafari, setIsIOSSafari] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [product, setProduct] = useState(null);
    const [deal, setDeal] = useState(null);
    const [deals, setDeals] = useState(null);
    const [productComparison, setProductComparison] = useState(null);
    const { getProduct, getProductPriceCompare, trackClaimDeal } = useApi();
    const navigate = useNavigate();
    const location = useLocation();
    const { trackGetDealClick, actionCounts } = useUserActions();
    const user = JSON.parse(localStorage.getItem('user')) ?? null;

    // New state and refs for pull-down functionality
    const MAX_MAP_HEIGHT = 400;
    const MIN_MAP_HEIGHT = 135;

    // Dismiss Handler
    const sheetRef = useRef(null);
    const bottomSheetRef = useRef(null);

    const fetchProduct = useCallback(async (productId) => {
        try {

            if (productOrig) {
                productOrig.brand = {
                    name: productOrig.brand_name
                }
                setProduct(productOrig);
                setDeal(productOrig.product_deal[0]);
                setDeals(productOrig.product_deal);
            }

            const productData = await getProduct(productId);
            setProduct(productData);
            setDeal(productData.product_deal[0]);
            setDeals(productData.product_deal);

        }
        catch (error) {
            console.error("Error fetching product:", error);
            //handleClose();
        }
    }, [getProduct]);

    const fetchPriceCompare = useCallback(async (productId) => {
        try {

            const result = await getProductPriceCompare(productId);

            setProductComparison(result);
        }
        catch (error) {
            console.error("Error fetching product price comparison:", error);
        }
    }, [getProductPriceCompare]);

    // Update URL with product ID when component opens
    useEffect(() => {
        if (isOpen && productOrig?.id) {
            // Create a new URLSearchParams object based on the current query string
            const searchParams = new URLSearchParams(location.search);
            // Set the product parameter
            searchParams.set('product', productOrig.id);

            // Update the URL without causing a navigation/refresh
            const newUrl = `${location.pathname}?${searchParams.toString()}${location.hash}`;
            window.history.pushState({}, '', newUrl);

            // Set the page title based on product info
            if (productOrig.name && productOrig?.brand_name) {
                document.title = `${productOrig.name} by ${productOrig.brand_name} | Deal Details`;
            } else if (productOrig.name) {
                document.title = `${productOrig.name} | Deal Details`;
            }
        } else if (!isOpen) {
            // Remove the product parameter when closing
            const searchParams = new URLSearchParams(location.search);
            if (searchParams.has('product')) {
                searchParams.delete('product');
                const newUrl = searchParams.toString()
                               ? `${location.pathname}?${searchParams.toString()}${location.hash}`
                               : `${location.pathname}${location.hash}`;
                window.history.pushState({}, '', newUrl);
            }

            // Reset the title when closing
            document.title = 'pluug - Shop & Save Weed Deals Near You';
        }
    }, [isOpen, productOrig, location]);

    useEffect(() => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        setIsIOSSafari(isIOS && isSafari);

        if (isIOS && isSafari) {
            setCustomStyle(
                {
                    maxHeight: `calc(100vh - ${isNavExpanded ? '96px' : '96px'})`
                }
            );
        }

        if (isOpen) {
            document.body.style.overflowY = "hidden";

            if (productOrig?.id) {

                fetchProduct(productOrig.id);
                fetchPriceCompare(productOrig.id);
            } else if (productId) {
                fetchProduct(productId);
                fetchPriceCompare(productId);
            } else {
                console.warn("No productId found in the deal object");
            }
        } else if (!isOpen) {
            setIsClosing(false);
            setProduct(null);
        }

        return () => {
            document.body.style.overflowY = "auto";
        };
    }, [isOpen, productOrig]);

    const handleClose = useCallback(() => {
        setIsClosing(true);
        document.body.style.overflowY = "auto";

        // Remove product query parameter on close
        const searchParams = new URLSearchParams(window.location.search);
        console.log(searchParams.toString());
        console.log(searchParams.get('product'));
        if (searchParams.has('product')) {
            searchParams.delete('product');
            const newUrl = searchParams.toString() ? `${location.pathname}` : `${location.pathname}${location.hash}`;
            console.log('Changing url to', newUrl);

            window.history.pushState({}, '', newUrl);
        }

        setTimeout(() => {
            onClose();
        }, 300);
    }, [onClose]);

    const handleDealClick = (link) => {

        try {
            trackGetDealClick();
        } catch (error){
            console.log(error);
        }
        trackClaimDeal(product.id, product.dispensary_id);
        const user = JSON.parse(localStorage.getItem('user'));

        console.log(user);
        console.log(user?.id);
        if (user?.id !== undefined || actionCounts.getDealClick < 6) {
            return window.open(link, '_blank');
        }

        eventBus.emit('unauthorized', {
            'onProduct': true,
            'redirect': link
        });
    }

    const renderNoDealFooter = () => {
        if (!product?.dispensary) return null;

        return (
            <div className={styles.bottomSheetFooter}>
                <div className={styles.bottomSheetButtons}>

                    <Link to="/" className={styles.bottomSheetHome}>
                        Home
                    </Link>

                    <Link
                        to={`/business/${product.dispensary_id}`}
                        className={styles.bottomSheetViewStore}
                    >
                        View Store
                    </Link>

                    <a
                        target="_blank"
                        href={product.link}
                        className={`${styles.bottomSheetGetDeal} ${isDarkMode ? styles.bottomSheetGetDealDark:''}`}
                    >
                        {deal && (<span className={`z-10 relative`}>Get Deal</span>)}
                        {!deal && (<span className={`z-10 relative`}>Buy Product</span>)}

                    </a>
                </div>
            </div>
        );
    };

    const renderFooter = () => {
        if (!product?.dispensary) return null;

        return (
            <div className={styles.bottomSheetFooter}>
                <div className={`${styles.bottomSheetButtons} flex flex-col justify-center text-center`}>


                    {(product?.dispensary?.state === 'FLACK') && (
                        <span className={`text-xs max-w-[360px]`}>This deal is active at this dispensary. You may need to visit their website to shop online or visit in store to claim.</span>
                    )}

                    {(product?.dispensary?.state !== 'FLACK') && (
                        <>
                            {product?.product?.dutchie_id !== null && (
                                <span className={`text-xs max-w-[360px]`}>You will be redirected to Dutchie to claim this deal.<br/> Be patient. Sometimes Dutchie's website is slow to load.</span>
                            )}
                            <a
                                target="_blank"
                                onClick={(e) => handleDealClick(product.link)}
                                className={`${styles.bottomSheetGetDeal} ${isDarkMode ? styles.bottomSheetGetDealDark : ''}`}
                            >

                                <span className={`z-10 relative`}>Get Deal</span>

                            </a>
                        </>

                    )}

                </div>
            </div>
        );
    };

    if (!isOpen && !isClosing) return null;

    return ReactDOM.createPortal(
        <div
            className={`${styles.bottomSheetOverlay} ${isClosing ? styles.closing : ''}`}
            ref={sheetRef}
            onClick={handleClose}
        >

            {!product ? (
                <div
                    className={`${styles.bottomSheetContent} ${isIOSSafari ? styles.iosBottomSheetContent : ''} ${isOpen ? styles.open : ''} ${isClosing ? styles.closing : ''} flex justify-center`}
                    data-is-nav-expanded={isNavExpanded}
                    style={customStyle}
                    ref={bottomSheetRef}
                    onClick={(e) => e.stopPropagation()}
                >
                    <p className={`text-center`}>Loading product information...</p>
                    <LottieIcon width={360} height={360} variant={'weedBag02'} className={styles.lottieSvg}/>
                </div>
            ) : (
                 <div
                     className={`${styles.bottomSheetContent} ${isIOSSafari ? styles.iosBottomSheetContent : ''} ${isOpen ? styles.open : ''} ${isClosing ? styles.closing : ''}`}
                     data-is-nav-expanded={isNavExpanded}
                     style={customStyle}
                     ref={bottomSheetRef}
                     onClick={(e) => e.stopPropagation()}
                 >
                     <button className={styles.bottomSheetCloseButton} onClick={handleClose}>
                         <X size={24}/>
                     </button>
                     <ProductView selectedWeightUnit={selectedWeightUnit}
                                  product={product}
                                  sheetRef={bottomSheetRef}
                                  priceFrom={priceFrom}
                                  priceTo={priceTo}
                                  distance={distance}
                                  selectedWeight={selectedWeight}
                                  businessId={businessId}
                                  productOrig={productOrig}
                                  deal={deal}
                                  onClose={onClose}
                                  similarProducts={productComparison?.similar_products}
                                  deals={deals}/>
                     {deal ? (
                         renderFooter()
                     ) : (
                          renderNoDealFooter()
                      )}
                 </div>
             )}


        </div>,
        document.body
    );
};

export default DealBottomSheetV2;
