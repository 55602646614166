import React, {useEffect, useState, useCallback, useRef} from "react";
import ReactDOM from "react-dom";
import {Link, useNavigate} from "react-router-dom";
import styles from "../DealList/DealList.module.css";
import {X, MapPin, Phone, Clock} from "lucide-react";
import useApi from "../../utils/useApi";
import StarRating from "../StarRating/StarRating";
import ProductActions from "../ProductActions/ProductActions";
import Pricing from "../Pricing/Pricing";
import {HiDevicePhoneMobile} from "react-icons/hi2";
import useSafariNavBarState from "../../hooks/useSafariNavBarState";
import ProductDeals from "../ProductDeals/ProductDeals";
import MapComponent from "../MapComponent/MapboxMapComponent";
import Filters from "../Filters/Filters";
import {useGeolocation} from "../../contexts/GeolocationProvider";
import PullIndicator from "../PullIndicator/PullIndicator";
import useIsPhone from "../../hooks/useIsPhone";
import {useTheme} from "../ThemeContext/ThemeContext";
import GradientButton from "../../UI/GradientButton/GradientButton";

const DealBottomSheet = ({
                             businessId,
                             isOpen,
                             onClose,
                             deal,
                             deals,
                             productOrig,
                             hideStore,
                             selectedWeight,
                             selectedWeightUnit,
                             priceFrom,
                             priceTo,
                             distance,
                         }) => {
    const { isDarkMode } = useTheme();
    const isPhone = useIsPhone();
    const navigate = useNavigate();
    const { geoposition } = useGeolocation();
    const [customStyle, setCustomStyle] = useState({});
    const isNavExpanded = useSafariNavBarState();
    const [isIOSSafari, setIsIOSSafari] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [product, setProduct] = useState(null);
    const { getProduct } = useApi();
    const useGradient = false;
    const labelStyle = useGradient ? { 'color': 'white' } : {};
    const [mapHeight, setMapHeight] = useState(135);
    const [mapZoom, setMapZoom] = useState(15);

    // New state and refs for pull-down functionality
    const MAX_MAP_HEIGHT = 400;
    const MIN_MAP_HEIGHT = 135;
    const isMapExpanded = mapHeight === MAX_MAP_HEIGHT;
    const touchStartRef = useRef(0);
    const currentTouchRef = useRef(0);
    const isDraggingRef = useRef(false);
    const scrollContentRef = useRef(null);
    const mapContainerRef = useRef(null);
    const mapInstanceRef = useRef(null);

    // Dismiss Handler
    const [startY, setStartY] = useState(0);
    const [currentY, setCurrentY] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const sheetRef = useRef(null);



    // Handle map instance
    const handleMapLoad = useCallback((mapRef) => {
        mapInstanceRef.current = mapRef;
    }, []);



    // Resize map when height changes
    useEffect(() => {
        if (mapInstanceRef.current) {
            setTimeout(() => {
                mapInstanceRef.current.resize();
            }, 20); // Small delay to ensure transition is complete
        }
    }, [mapHeight]);

    const handleTouchStart = (e) => {
        if (!scrollContentRef.current || isMapExpanded) return;

        const touch = e.touches[0];
        touchStartRef.current = touch.clientY;
        currentTouchRef.current = touch.clientY;

        // Start dragging if we're at the top of the scroll or if the map is expanded
        if (scrollContentRef.current.scrollTop <= 0 || mapHeight > MIN_MAP_HEIGHT) {
            isDraggingRef.current = true;
        }
    };

    const handleTouchMove = (e) => {
        const touch = e.touches[0];
        const deltaY = touch.clientY - currentTouchRef.current;
        currentTouchRef.current = touch.clientY;
        console.log(deltaY*3);

        if(deltaY > 0 && deltaY*3 > 136 && scrollContentRef.current?.scrollTop <= 0){
            //handleClose();
        }

        if (!isDraggingRef.current || isMapExpanded) return;
        // If the map is expanded or we're trying to expand it, prevent default scroll
        if (mapHeight > MIN_MAP_HEIGHT || (deltaY < 0 && scrollContentRef.current?.scrollTop <= 0)) {
            //e.preventDefault();


            const newHeight = Math.min(
                Math.max(mapHeight + (deltaY*3), MIN_MAP_HEIGHT),
                MAX_MAP_HEIGHT
            );
            setMapHeight(newHeight);


        }
        // If we're at the top of the scroll and trying to expand, allow map expansion
        else if (scrollContentRef.current?.scrollTop === 0) {
            const newHeight = Math.min(
                Math.max(mapHeight + deltaY, MIN_MAP_HEIGHT),
                MAX_MAP_HEIGHT
            );
            setMapHeight(newHeight);
        }
    };

    const handleTouchEnd = () => {
        if (!isDraggingRef.current || isMapExpanded) return;

        isDraggingRef.current = false;
        // Snap to min or max height based on current position
        const midPoint = (MAX_MAP_HEIGHT + MIN_MAP_HEIGHT) / 2;
        if (mapHeight > midPoint) {
            setMapHeight(MAX_MAP_HEIGHT);
        } else {
            setMapHeight(MIN_MAP_HEIGHT);
        }
    };

    const fetchProduct = useCallback(async (productId) => {
        try {

            const productData = await getProduct(productId);

            setProduct(productData);
        }
        catch (error) {
            console.error("Error fetching product:", error);
        }
    }, [getProduct]);

    useEffect(() => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        setIsIOSSafari(isIOS && isSafari);

        if (isIOS && isSafari) {
            setCustomStyle(
                {
                    maxHeight: `calc(100vh - ${isNavExpanded ? '96px' : '96px'})`
                }
            );
        }

        if (isOpen && deal) {
            document.body.style.overflowY = "hidden";

            if (productOrig?.id) {

                fetchProduct(productOrig.id);
            } else {
                console.warn("No productId found in the deal object");
            }
        } else if (!isOpen) {
            setIsClosing(false);
            setProduct(null);
        }

        return () => {
            document.body.style.overflowY = "auto";
        };
    }, [isOpen, productOrig, fetchProduct, deal]);

    const handleClose = useCallback(() => {
        setIsClosing(true);
        setMapHeight(MIN_MAP_HEIGHT);
        document.body.style.overflowY = "auto";

        setTimeout(() => {
            setShowDescription(false);
            onClose();
        }, 300);
    }, [onClose]);

    // New wrapper component for Link that includes close functionality
    const ClosingLink = ({ to, className, businessId, children }) => {
        const isCurrentBusinessPage = location.pathname === `/business/${businessId}`;

        if (isCurrentBusinessPage) {
            return (
                <div
                    className={className}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClose();
                    }}
                >
                    {children}
                </div>
            );
        }

        return (
            <Link
                to={to}
                className={className}
                onClick={(e) => {
                    e.stopPropagation();
                    handleClose();
                }}
            >
                {children}
            </Link>
        );
    };

    // New wrapper for external links
    const ClosingExternalLink = ({ href, className, children }) => (
        <a
            href={href}
            className={className}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
                e.stopPropagation();
                handleClose();
            }}
        >
            {children}
        </a>
    );

    const viewMore = (e) => {
        e.stopPropagation();
        setShowDescription(true);
    };

    const closeViewMore = (e) => {
        e.stopPropagation();
        setShowDescription(false);
    };

    const formatSchedule = (schedule) => {
        if (!schedule) return "Not available";
        const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const today = daysOfWeek[new Date().getDay()];
        const todaySchedule = schedule[today];

        if (!todaySchedule || !todaySchedule.isOpen) return "Closed today";

        const formatTime = (timeString) => {
            const [, time] = timeString.split('T');
            const [hours, minutes] = time.split(':');
            let hour = parseInt(hours, 10);
            const ampm = hour >= 12 ? 'PM' : 'AM';
            hour = hour % 12 || 12;
            return `${hour}:${minutes} ${ampm}`;
        };

        const openTime = formatTime(todaySchedule.open);
        const closeTime = formatTime(todaySchedule.close);
        return `${openTime} - ${closeTime}`;
    };

    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleBusinessClick = (business) => {
        console.log(business);
        navigate(`/business/${business.id}`);
    }

    const handlePullIndicatorClick = () => {

        if (isMapExpanded) {
            setMapZoom(15);
            setMapHeight(MIN_MAP_HEIGHT);
        } else if(!isMapExpanded){
            setMapHeight(MAX_MAP_HEIGHT);
            setMapZoom(20);

        }
    };

    const renderMap = () => {
        if (!product) return null;

        const mapMarker = Filters.getById(product.parentCategoryId).mapMarker;

        return (
            <div
                ref={mapContainerRef}
                style={{
                    height: `${mapHeight}px`,
                    transition: 'height 0.3s ease-out',
                    position: 'relative',
                    overflow: 'hidden'
                }}
            >
                <MapComponent
                    mapMarker={mapMarker}
                    customZoom={mapZoom}
                    businesses={[product.dispensary]}
                    geoposition={geoposition}
                    customHeight={`${mapHeight}px`}
                    customClass={styles.map}
                    onLoad={handleMapLoad}
                    onBusinessClick={handleBusinessClick}
                />

            </div>
        );
    };

    const marginRight = deal.imageUrl ? 15:0;

    const renderProductDetails = () => {
        if (!product) return <p>Loading product information...</p>;

        return (
            <div className={styles.bottomSheetProductInfo}>
                <PullIndicator
                    isExpanded={isMapExpanded}
                    onClick={handlePullIndicatorClick}
                />
                <div className={styles.bottomSheetProductWrap}>
                    <div className={styles.cardTopColor}></div>
                    <div className={`${styles.cardBackground} ${isDarkMode ? styles.cardBackgroundDarkMode:''} ${styles[product?.strain_type?.name.toLowerCase()]}`}></div>
                    <div className={styles.productContent}>
                        <div className={styles.bottomSheetProductImageWrapper}>
                            <img
                                src={product.formatted_thumbnail_url || 'https://product-assets.iheartjane.com/cdn-cgi/image/width=1200,fit=scale-down,format=auto,metadata=none/default-photos/flower/hybrid.png'}
                                alt={product.name}
                                style={{marginRight: marginRight}}
                                className={styles.bottomSheetProductImage}
                            />
                            {(deal && !product.jane_id && deal.imageUrl) && (
                                <img src={deal.imageUrl} alt={deal.title} className={styles.bottomSheetDealImage}/>
                            )}
                        </div>

                        <div className={styles.bottomSheetDetailWrap}>
                            <div className={styles.bottomSheetProductDetails}>
                                <div className={styles.productNameDetails}>
                                    <h3 style={labelStyle}>{product.name}</h3>
                                    {product.brand?.name && (
                                        <span className={`${styles.bottomSheetBrandName} tour-brand-name`} style={labelStyle}>
                                        {product.brand.name}
                                    </span>
                                    )}
                                </div>

                                {product.pricing.length <= 0 && (
                                    <p className={styles.bottomSheetProductPrice}>
                                        {product.sort_price ? (
                                            <>
                                            <span className={styles.bottomSheetOriginalPrice}>
                                                ${parseFloat(product.price).toFixed(2)}
                                            </span>
                                                <span className={styles.bottomSheetSalePrice}>
                                                ${parseFloat(product.sort_price).toFixed(2)}
                                            </span>
                                            </>
                                        ) : (
                                             `$${parseFloat(product.price).toFixed(2)}`
                                         )}
                                    </p>
                                )}
                            </div>

                            <div className={styles.bottomSheetMetaVoteRow}>
                                <div className={`${styles.productInfoLeft}`}>
                                    <div className={`${styles.productMetadata} tour-product-metadata`}>
                                        {/* {product?.product?.normalized_quantity && (
                                            <span className={`${styles.productLabels} ${styles[product?.strain_type?.name?.toLowerCase()]} tour-product-quantity`}
                                                  style={labelStyle}
                                            >
                                            {product.product.normalized_quantity}
                                        </span>
                                        )} */}
                                        {product?.strain_type?.name && (
                                            <span className={`${styles.productLabels} ${styles.strainTypeLabel} ${styles[product.strain_type.name.toLowerCase()]} tour-strain-type`}
                                                  style={labelStyle}
                                            >
                                            {capitalizeFirstLetter(product.strain_type.name)}
                                        </span>
                                        )}
                                        {product.product_category && (
                                            <span className={`${styles.productLabels} tour-product-category`}
                                                  style={labelStyle}
                                            >
                                            {product.product_category}
                                        </span>
                                        )}
                                        {product.thc_content_label && product.thc_content_label !== "-" && (
                                            <span className={`${styles.thcContent} tour-thc-content`}
                                                  style={labelStyle}
                                            >
                                            {product.thc_content_label} THC
                                        </span>
                                        )}
                                        {product.cbd_content_label && product.cbd_content_label !== "-" && (
                                            <span className={`${styles.cbdContent} tour-cbd-content`}
                                                  style={labelStyle}
                                            >
                                            {product.cbd_content_label} CBD
                                        </span>
                                        )}
                                        {product.dispensary && (
                                            <span className={`${styles.dispensaryName} tour-dispensary-name`}
                                                  style={labelStyle}
                                            >
                                            {product.dispensary.name}
                                        </span>
                                        )}
                                        {distance !== undefined && (
                                            <span className={`${styles.dispensaryName} tour-distance`}
                                                  style={labelStyle}
                                            >
                                            {distance} mi
                                        </span>
                                        )}
                                    </div>
                                    {(deal && !product.jane_id) && (
                                        <div className={styles.bottomSheetDealInfo}>
                                            <ProductDeals product={product}
                                                          styles={styles}
                                                          strainType={product?.strain_type?.name.toLowerCase()}
                                                          labelStyle={labelStyle}
                                                          includeFinePrint={true}
                                                          includeEndsAt={true}
                                                          condition={!product.jane_id}/>
                                        </div>
                                    )}
                                </div>
                                <ProductActions product={product}/>
                            </div>

                            <div className={styles.productDescription}>
                                {!showDescription ? (
                                    <p>
                                        {product.description && product.description.length > 100
                                         ? `${product.description.slice(0, 100)}... `
                                         : product.description}
                                        {product.description?.length > 100 && (
                                            <span className={styles.moreLink} onClick={viewMore}>
                                            More
                                        </span>
                                        )}
                                    </p>
                                ) : (
                                     <p onClick={closeViewMore}>{product.description}</p>
                                 )}
                            </div>
                        </div>

                        {product.pricing.length > 0 && (

                            <Pricing
                                labelStyle={labelStyle}
                                deal={product?.product_deal[0]}
                                pricing={product.pricing}
                                selectedWeight={selectedWeight}
                                selectedWeightUnit={selectedWeightUnit}
                                priceFrom={priceFrom}
                                priceTo={priceTo}
                                customClass={styles.pricingTiers}
                            />
                        )}
                    </div>

                </div>

                <h2 className={styles.attentionLabel}>Deal Terms</h2>
                <p>
                    Deals may appear active, but could be expired or out of stock. They are time-limited and subject to change or cancellation. pluug is not responsible for deal management and pricing. Taxes and fees may vary. Contact this location for any questions.
                </p>
            </div>
        );
    };

    const renderDispensaryInfo = () => {
        if (!product?.dispensary) return null;

        return (
            <div className={styles.bottomSheetDispensaryInfo}>
                <ClosingLink to={`/business/${product.dispensary_id}`} className={styles.dispensaryHeader} businessId={businessId}>
                    <img
                        src={product.dispensary.logoImage}
                        alt={product.dispensary.name}
                        className={styles.dispensaryLogo}
                    />
                    <div>
                        <h3>{product.dispensary.name}</h3>
                        <div className={styles.ratingWrapper}>
                            <StarRating
                                rating={product.dispensary.starRating}
                                maxRating={5}
                                size={15}
                            />
                            {product.dispensary.starRating !== null && (
                                <span style={{ marginRight: 3 }}>{product.dispensary.starRating.toFixed(1)} </span>
                            )}
                            {product.dispensary.starRating === null && (
                                <span style={{ marginRight: 3 }}>0</span>
                            )}

                            <span>({product.dispensary.numberOfReviews ?? 0} reviews)</span>
                        </div>
                    </div>
                </ClosingLink>

                <div className={styles.dispensaryDetails}>
                    <p>
                        <MapPin size={16}/>
                        <ClosingExternalLink
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                `${product.dispensary.name} ${product.dispensary.address1}`
                            )}`}
                        >
                            {product.dispensary.address1}, {product.dispensary.city},
                            {product.dispensary.state} {product.dispensary.zip}
                        </ClosingExternalLink>
                    </p>
                    <p>
                        <HiDevicePhoneMobile size={16}/>
                        <ClosingExternalLink href={`tel:${product.dispensary.phone}`}>
                            {product.dispensary.phone}
                        </ClosingExternalLink>
                    </p>
                    <p>
                        <Clock size={16}/>
                        {formatSchedule(product.dispensary.schedule)}
                    </p>
                </div>
            </div>
        );
    };

    const renderFooter = () => {
        if (!product?.dispensary) return null;

        return (
            <div className={styles.bottomSheetFooter}>
                <div className={styles.bottomSheetButtons}>
                    <div
                        onClick={handleClose}
                        className={styles.bottomSheetClose}
                    >
                        Close
                    </div>
                    {(!hideStore && !businessId) && (
                        <ClosingLink
                            to={`/business/${product.dispensary_id}`}
                            className={`${styles.bottomSheetViewStore}`}
                        >
                            <GradientButton textColor={`text-white`} gradientFrom={'branding-indigo'} gradientTo={'branding-purple'} text={'View Store'}/>
                        </ClosingLink>
                    )}
                    <a
                        target="_blank"
                        href={product.link}
                        className={styles.bottomSheetGetDeal}
                    >
                        <GradientButton textColor={`text-black`} gradientFrom={'branding-green'} gradientTo={'branding-green-dark'} text={'Get Deal'}/>

                    </a>
                </div>
            </div>
        );
    };

    if (!isOpen && !isClosing) return null;

    return ReactDOM.createPortal(
        <div
            className={`${styles.bottomSheetOverlay} ${isClosing ? styles.closing : ''}`}
            ref={sheetRef}
            onClick={handleClose}
        >

            <div
                className={`${styles.bottomSheetContent} ${isIOSSafari ? styles.iosBottomSheetContent : ''} ${isOpen ? styles.open : ''} ${isClosing ? styles.closing : ''}`}
                data-is-nav-expanded={isNavExpanded}
                style={customStyle}
                onClick={(e) => e.stopPropagation()}
            >
                <button className={styles.bottomSheetCloseButton} onClick={handleClose}>
                    <X size={24}/>
                </button>
                <div ref={scrollContentRef}
                     className={styles.bottomSheetScrollContent}
                     onTouchStart={handleTouchStart}
                     onTouchMove={handleTouchMove}
                     onTouchEnd={handleTouchEnd}
                >
                    {renderMap()}
                    <div className={styles.bottomSheetDealContent}>
                        {renderProductDetails()}
                        {renderDispensaryInfo()}
                    </div>

                </div>
                {renderFooter()}
            </div>
        </div>,
        document.body
    );
};

export default DealBottomSheet;
